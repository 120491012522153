import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
const seekerApi = factory.get("seeker");
const initialState = {
  seeker: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: null,
    },
    dialog: false,
  },
  seekerProfile: {
    loading: false,
    dialog: false,
    profile: {
      id: "",
    },
    total: 0,
  },
};

export const seekerSlice = createSlice({
  name: "seeker",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setSeeker: (state, action) => {
      state.seeker.data = action.payload.data;
      state.seeker.total = action.payload.total;
    },
    setSeekerProfile: (state, action) => {
      state.seekerProfile.profile = action.payload;
    },
    setSeekerProfileDialog: (state, action) => {
      if (action.payload?.id) {
        state.seekerProfile.profile.id = action.payload.id;
      }
      state.seekerProfile.dialog = !state.seekerProfile.dialog;
    },
    setFilters: (state, { payload }) => {
      state.seeker.filters = {
        ...state.seeker.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.seeker.filters = initialState.seeker.filters;
    },
  },
});

export const {
  setLoading,
  setSeeker,
  setFilters,
  resetFilters,
  setSeekerProfile,
  setSeekerProfileDialog,
} = seekerSlice.actions;

export default seekerSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().seeker.seeker.filters;
    dispatch(setLoading("seeker"));
    const res = await seekerApi.index(filters);
    dispatch(setSeeker(res.data));
    dispatch(setLoading("seeker"));
  } catch (err) {
    dispatch(setLoading("seeker"));
    console.log(err);
  }
};

const showById = (id) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("seekerProfile"));
    const res = await seekerApi.show(id);
    dispatch(setSeekerProfile(res.data?.data[0]));
    dispatch(setLoading("seekerProfile"));
  } catch (err) {
    dispatch(setLoading("seekerProfile"));
    console.log(err);
  }
};

const deleteSeeker = (id) => async (dispatch, getState) => {
  try {
    await seekerApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Seeker deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const Seeker = {
  index,
  showById,
  deleteSeeker,
};
