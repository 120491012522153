import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";

const AlertDialog = ({
  open,
  handleClose,
  action,
  title = "Delete Data",
  message = "Are you sure you want to delete this data",
  yesTitle = "Yes",
  cancelTitle = "cancel",
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={props.maxWidth ? props.maxWidth : "sm"}
      sx={{
        width: "100%",
        overflow: "hidden",
        borderRadius: 10,
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderBottom: "1px solid #bdc3c7",
        }}
      >
        <Typography>{title}</Typography>
      </DialogTitle>
      <DialogContent
        sx={{ minWidth: "280px", width: "70vw", maxWidth: "550px" }}
      >
        <DialogContentText id="alert-dialog-description">
          <Typography>{message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}
          sx={{ textTransform: "capitalize" }}
        >
          <Typography>{cancelTitle}</Typography>
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize" }}
          color="primary"
          onClick={() => {
            action();
            handleClose();
          }}
          autoFocus
        >
          <Typography>{yesTitle}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
