import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Index from "./pages/Index";
import { Cities } from "./pages/Cities";
import { Box } from "@mui/material";
import { JobTitle } from "./pages/JobTitle";
import { Employer } from "./pages/Employer";
import { JobField } from "./pages/JobField";
import { Jobs } from "./pages/Jobs";
import { Seeker } from "./pages/Seeker";
import { Banner } from "./pages/Banner";
import { ProfileSeekerView } from "./pages/ProfileSeekerView";
import { Notifications } from "./pages/Notifications";
import ApplecantStages from "./pages/ApplecantStages";
import CvTemplates from "./pages/CvTemplates";
import { AdminNotes } from "./pages/AdminNotes";
import { Feedback } from "./pages/Feedback";
import { EmployerField } from "./pages/EmployerField";
import { Admins } from "./pages/Admins";
import ProtectedRoute from "./ProtectedRoute";
import useFetch from "./hooks/useFetch";
import { setPendingStats } from "./app/slices/appSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
const RouterApp = () => {
  const dispatch = useDispatch();
  const { refetch } = useFetch("/admin/statistics/pending-statistics", {
    formatData: (res) => res.data.data,
    onSuccess: (data) => {
      dispatch(setPendingStats(data));
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ mt: 5, ml: 5, mr: 2 }}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Index />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cities"
            element={
              <ProtectedRoute>
                <Cities />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-title"
            element={
              <ProtectedRoute>
                <JobTitle />
              </ProtectedRoute>
            }
          />
          <Route
            path="/job-field"
            element={
              <ProtectedRoute>
                <JobField />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer"
            element={
              <ProtectedRoute>
                <Employer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/jobs"
            element={
              <ProtectedRoute>
                <Jobs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/applicant-stages"
            element={
              <ProtectedRoute>
                <ApplecantStages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/seeker"
            element={
              <ProtectedRoute>
                <Seeker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/banner"
            element={
              <ProtectedRoute>
                <Banner />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile-seeker/:id"
            element={
              <ProtectedRoute>
                <ProfileSeekerView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cv-templates"
            element={
              <ProtectedRoute>
                <CvTemplates />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-notes"
            element={
              <ProtectedRoute>
                <AdminNotes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/feedback"
            element={
              <ProtectedRoute>
                <Feedback />
              </ProtectedRoute>
            }
          />
          <Route
            path="/employer-field"
            element={
              <ProtectedRoute>
                <EmployerField />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admins"
            element={
              <ProtectedRoute>
                <Admins />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </Box>
  );
};

export default RouterApp;
