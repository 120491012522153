import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import PasswordIcon from "@mui/icons-material/Password";
import ModalChangePassword from "./Modal/ModalChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { Auth, setChangePasswordDialog } from "../../app/slices/authSlice";

export const MenuProfile = () => {
  const dispatch = useDispatch();
  const {
    changePassword: { dialog },
  } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChangePassword = () => {
    dispatch(setChangePasswordDialog());
  };

  const handleLogOut = () => {
    dispatch(Auth.logout());
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup={true}
          aria-expanded={open ? true : undefined}
        >
          <Avatar sx={{ width: 42, height: 42, color: "#1a8b9d" }}></Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        keepMounted
        // slotProps={{
        //   paper: {
        //     elevation: 0,
        //     sx: {
        //       overflow: "visible",
        //       filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        //       mt: 1.5,
        //       "& .MuiAvatar-root": {
        //         width: 32,
        //         height: 32,
        //         ml: -0.5,
        //         mr: 1,
        //       },
        //       "&:before": {
        //         content: '""',
        //         display: "block",
        //         position: "absolute",
        //         top: 0,
        //         right: 14,
        //         width: 10,
        //         height: 10,
        //         bgcolor: "background.paper",
        //         transform: "translateY(-50%) rotate(45deg)",
        //         zIndex: 0,
        //       },
        //     },
        //   },
        // }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          Change password
        </MenuItem>
      </Menu>
      {dialog && <ModalChangePassword />}
    </Box>
  );
};
