import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import Cookies from "js-cookie";
import { setProfile } from "./profileSlice";
import { useNavigate } from "react-router-dom";

const authApi = factory.get("auth");

const initialState = {
  auth: {
    loading: false,
    token: Cookies.get("token") || "",
  },
  form: {
    email: "",
    password: "",
  },
  changePassword: {
    dialog: false,
    loading: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setToken: (state, action) => {
      Cookies.set("token", action.payload);
      // window.location.href = "/";
    },
    resetForm: (state, action) => {
      state.form = initialState.form;
    },
    setChangePasswordDialog: (state, action) => {
      state.changePassword.dialog = !state.changePassword.dialog;
    },
  },
});

export const { setLoading, setToken, resetForm, setChangePasswordDialog } =
  authSlice.actions;

export default authSlice.reducer;

//axios
const login = (data, navigate) => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    const res = await authApi.login(data);
    dispatch(setToken(res.data.token));
    dispatch(setProfile(res.data.data[0]));
    dispatch(setLoading("auth"));
    navigate("/");
  } catch (err) {
    dispatch(setLoading("auth"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading("auth"));
    await authApi.logout().then((res) => {
      Cookies.remove("token");
    });
    dispatch(setLoading("auth"));
    window.location.href = "/login";
  } catch (err) {
    dispatch(setLoading("auth"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const changePassword = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("changePassword"));
    const res = await authApi.changePassword(data);
    dispatch(setToken(res.data.token));
    dispatch(setProfile(res.data.data[0]));
    dispatch(setLoading("changePassword"));
  } catch (err) {
    dispatch(setLoading("changePassword"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};
export const Auth = {
  login,
  logout,
  changePassword,
};
