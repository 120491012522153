import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSetRecoilState } from "recoil";
import { ColumnText } from "../DataGridTable/ColumnText";
import { ColumnButton } from "../DataGridTable/ColumnButton";
import { HeaderGridTable } from "../DataGridTable/HeaderGridTable";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { ChangeStatusByChips } from "./ChangeStatusByChips";
import ModalInfo from "../JobTitle/Modal/ModalInfo";
import Filter from "./Filters";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminNotes,
  resetFilters,
  setFilters,
  setFiltersDialog,
} from "../../app/slices/adminNotesSlice";
import { useDebounce } from "use-debounce";
import { DataGridTable } from "../DataGridTable/DataGridTable";
import { setDialog } from "../../app/slices/Job/jobTitleSlice";
import dayjs from "dayjs";

export const Tabel = () => {
  const dispatch = useDispatch();
  const {
    adminNotes: { data, loading, filters, total },
  } = useSelector((state) => state.adminNotes);
  const {
    manageJobTitle: { dialog },
  } = useSelector((state) => state.jobTitle);
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 600);
  const changeNoteStatus = (id, status) => {
    let infoData = {
      status: status,
    };
    dispatch(AdminNotes.updateStatus(id, infoData));
  };
  const columns = [
    {
      field: "notes",
      headerName: "Notes",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={params.row.notes}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "employer-grid--header",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <ChangeStatusByChips
            id={params.row.id}
            status={params.row.status}
            onChangeStatus={(item) => {
              changeNoteStatus(params.row.id, item.id);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      headerClassName: "employer-grid--header",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <ColumnText
              text={dayjs(params.row.created_at).format("YYYY-MM-DD HH:mm")}
              variant="subtitle1"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      sortable: false,
      headerClassName: "employer-grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton onClick={() => dispatch(setDialog())} type={"add"} />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    dispatch(AdminNotes.index());
  };

  useEffect(() => {
    dispatch(setFilters({ search: debouncedSearch }));
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);
  return (
    <>
      <Box m={2}>
        <HeaderGridTable
          value={search}
          title="Admin Notes"
          icon={<HomeWorkIcon size={25} color={"primary"} />}
          fetchData={fetchData}
          isFilter={true}
          handleFilter={() => {
            dispatch(setFiltersDialog());
          }}
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Box mt={2}>
          <DataGridTable
            rowCount={total}
            rows={data}
            loading={loading}
            page={filters.page}
            columns={columns}
            pageSize={filters.take}
            onPageChange={(newPage) => {
              dispatch(
                setFilters({
                  skip: newPage * filters.take,
                  page: newPage,
                })
              );
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(
                setFilters({
                  take: newPageSize,
                })
              );
              if (filters.skip === 0) {
                dispatch(AdminNotes.index());
              } else {
                dispatch(
                  setFilters({
                    skip: 0,
                    page: 0,
                  })
                );
              }
            }}
          />
        </Box>
        {/* Modal */}
        {dialog && <ModalInfo />}
        {/* Filter */}
        <Filter fetchData={fetchData} />
      </Box>
    </>
  );
};

export default Tabel;
