import { Card, CardContent, LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { NoData } from "../Others/NoData";

export const DataGridTable = ({
  rowCount,
  rows,
  columns,
  loading,
  page,
  onPageChange,
  onPageSizeChange,
  ...props
}) => {
  const borders_shadow = {
    borderRadius: "12px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  return (
    <Card
      sx={{
        margin: 0,
        padding: 0,
        ...borders_shadow,
      }}
      className="card-data-grid"
    >
      <CardContent>
        <DataGrid
          sx={{
            height: "80vh",
            width: "100%",
            margin: 0,
            padding: 0,
            "& .employer-grid--header": {
              backgroundColor: "#1a8b9d",
              color: "#fff",
            },
            ...borders_shadow,
          }}
          pageSize={props.pageSize || 25}
          rowCount={rowCount}
          rows={rows}
          loading={loading}
          page={page}
          columns={columns}
          rowHeight={65}
          pagination
          paginationMode="server"
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: !loading && rows.length === 0 ? NoData : undefined,
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </CardContent>
    </Card>
  );
};
