import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../../api/apiFactory";
import { showNotification } from "../notificationSlice";
import dayjs from "dayjs";
const jobsApi = factory.get("jobs");
const initialState = {
  jobs: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
      company_id: null,
      job_title_id: null,
      job_field_id: null,
      province_id: null,
      expired: null,
    },
    dialog: false,
  },
  manageJob: {
    loading: false,
    dialog: false,
    form: {
      id: "",
      company_id: null,
      employer_name: "",
      job_title_id: null,
      other_job_title: "",
      work_days: "",
      work_type_id: null,
      description: "",
      due_to: dayjs().add(6, "month").format("YYYY-MM-DD"),
      full_address: "",
      education_degree_id: null,
      gender: "",
      salary_from: null,
      salary_to: null,
      city_id: null,
      province_id: null,
      currency: "",
      is_active: true,
    },
  },
  jobApplicant: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      job_id: "",
      page: 0,
      skip: 0,
      take: 25,
    },
    dialog: false,
  },
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setJobs: (state, action) => {
      state.jobs.data = action.payload.data;
      state.jobs.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.jobs.filters = {
        ...state.jobs.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.jobs.filters = initialState.jobs.filters;
    },
    setFiltersDialog: (state, action) => {
      state.jobs.dialog = !state.jobs.dialog;
    },
    setDialog: (state, { payload }) => {
      if (payload) {
        state.manageJob.form = payload;
      } else {
        state.manageJob.form = initialState.manageJob.form;
      }
      state.manageJob.dialog = !state.manageJob.dialog;
    },
    resetForm: (state, action) => {
      state.manageJob.form = initialState.manageJob.form;
    },
    setJobApplicant: (state, action) => {
      state.jobApplicant.data = action.payload.data;
      state.jobApplicant.total = action.payload.total;
    },
    setJobApplicantDialog: (state, { payload }) => {
      if (payload) {
        state.jobApplicant.filters.job_id = payload.job_id;
      } else {
        state.jobApplicant.filters.job_id =
          initialState.jobApplicant.filters.job_id;
      }
      state.jobApplicant.dialog = !state.jobApplicant.dialog;
    },
    setJobApplicantFilters: (state, { payload }) => {
      state.jobApplicant.filters = {
        ...state.jobs.filters,
        ...payload,
      };
    },
    resetJobApplicantFilters: (state) => {
      state.jobApplicant.filters = initialState.jobApplicant.filters;
    },
  },
});

export const {
  setLoading,
  setJobs,
  setFilters,
  resetFilters,
  setFiltersDialog,
  setJobApplicantDialog,
  setJobApplicantFilters,
  resetJobApplicantFilters,
  setJobApplicant,
  resetForm,
  setDialog,
} = jobsSlice.actions;

export default jobsSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().jobs.jobs.filters;
    const _filters = {
      ...filters,
      job_title_id: filters.job_title_id?.id || null,
      job_field_id: filters.job_field_id?.id || null,
      province_id: filters.province_id?.id || null,
      company_id: filters.company_id?.company_id || null,
      expired: filters.expired?.value,
    };
    dispatch(setLoading("jobs"));
    const res = await jobsApi.index(_filters);
    dispatch(setJobs(res.data));
    dispatch(setLoading("jobs"));
  } catch (err) {
    dispatch(setLoading("jobs"));
    console.log(err);
  }
};

const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJob"));
    const _data = {
      ...data,
      education_degree_id: data.education_degree_id?.id || null,
      job_title_id: data.job_title_id?.id || null,
      company_id: data.company_id.company_id,
      work_type_id: data.work_type_id.id,
      city_id: data.city_id.id,
    };
    await jobsApi.create(_data);
    dispatch(setLoading("manageJob"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job Created successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageJob"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const update = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading("manageJob"));
    const _data = {
      ...data,
      education_degree_id: data.education_degree_id?.id || null,
      job_title_id: data.job_title_id?.id || null,
      company_id: data.company_id.company_id,
      work_type_id: data.work_type_id.id,
      city_id: data.city_id.id,
    };
    await jobsApi.update(id, _data);
    dispatch(setLoading("manageJob"));
    dispatch(
      showNotification({
        type: "success",
        message: "Job updated successfully",
      })
    );

    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manageJob"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const deleteJob = (id) => async (dispatch, getState) => {
  try {
    await jobsApi.delete(id);
    dispatch(
      showNotification({
        type: "success",
        message: "Job deleted successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

const indexJobApplicant = () => async (dispatch, getState) => {
  try {
    const filters = getState().jobs.jobApplicant.filters;
    dispatch(setLoading("jobApplicant"));
    const res = await jobsApi.indexJobApplicant(filters);
    dispatch(setJobApplicant(res.data));
    dispatch(setLoading("jobApplicant"));
  } catch (err) {
    dispatch(setLoading("jobApplicant"));
    console.log(err);
  }
};

export const Jobs = {
  index,
  create,
  update,
  deleteJob,
  indexJobApplicant,
};
