import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const adminNotesApi = factory.get("adminNotes");
const initialState = {
  adminNotes: {
    loading: false,
    data: [],
    total: 0,
    filters: {
      page: 0,
      skip: 0,
      take: 25,
      search: "",
      status: "",
    },
    dialog: false,
  },
};

export const adminNotesSlice = createSlice({
  name: "adminNotes",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setAdminNotes: (state, action) => {
      state.adminNotes.data = action.payload.data;
      state.adminNotes.total = action.payload.total;
    },
    setFilters: (state, { payload }) => {
      state.adminNotes.filters = {
        ...state.adminNotes.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.adminNotes.filters = initialState.adminNotes.filters;
    },
    setFiltersDialog: (state, action) => {
      state.adminNotes.dialog = !state.adminNotes.dialog;
    },
  },
});

export const {
  setLoading,
  setAdminNotes,
  setFilters,
  resetFilters,
  setFiltersDialog,
} = adminNotesSlice.actions;

export default adminNotesSlice.reducer;

const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().adminNotes.adminNotes.filters;
    dispatch(setLoading("adminNotes"));
    const res = await adminNotesApi.index(filters);
    dispatch(setAdminNotes(res.data));
    dispatch(setLoading("adminNotes"));
  } catch (err) {
    dispatch(setLoading("adminNotes"));
    console.log(err);
  }
};

const updateStatus = (id, status) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("adminNotes"));
    await adminNotesApi.updateStatus(id, status);
    dispatch(setLoading("adminNotes"));
    dispatch(
      showNotification({
        type: "success",
        message: "Status updated successfully",
      })
    );
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("adminNotes"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    console.log(err);
  }
};

export const AdminNotes = {
  index,
  updateStatus,
};
